import { useState, useEffect, useMemo, useCallback } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useNavigate, useParams } from 'react-router-dom';
import { LabDsButton, LabDsSearchBar, LabDsTab } from 'v4web-components-react';
import {
  Container,
  SectionContent,
  Breadcrumb,
  SectionTitle,
  Section,
} from '../../components/LegacyV4Components';

import { KubernetesApiUnit, Unit, User } from '../../hooks/types';
import useDebounce from '../../hooks/useDebounce';

import * as S from './styles';
import { getUnit, getUnitBlock } from '../../services/requests/Unit/getUnit';
import { getUsersByUnitId } from '../../services/requests/Unit/getUsersByUnitId';
import { useToast } from '../../hooks/toast';
import { getEkyteCompany } from '../../services/requests/Stack/getEkyteCompany';

import { useAuth } from '../../hooks/auth';
import { RegisterEkyteModal } from '../../components/AtomicDesign/organisms/RegisterEkyteModal';
import { stackApi } from '../../services/api';
import {
  postPermissionEkyte,
  putPermissionEkyte,
} from '../../services/requests/Stack/setPermissionEkyteUnit';
import {
  getEkyteUser,
  InterfaceEkyteUser,
} from '../../services/requests/Stack/getEkyteUser';
import { ActionUser } from '../../components/AtomicDesign/molecules/actionUser';
import { EditUnit } from '../../components/AtomicDesign/molecules/EditUnit';
import ModalPermissionsEdit from '../../components/AtomicDesign/molecules/ModalPermissionsEdit';
import ModalLockUnit from '../../components/AtomicDesign/molecules/ModalLockUnit';
import {
  headerTable,
  headerTableEkyte,
  headerTableHistoric,
  headerTableNotAdmin,
} from '../../utils/table';
import { TableSimpleProps } from '../../components/AtomicDesign/atoms/TableSimple/interfaces';
import { tableDataSimplePropsUnit } from '../Heaquarter/interfaces';
import MenuActions from '../../components/AtomicDesign/atoms/MenuActions';
import MenuEkyte from '../../components/AtomicDesign/atoms/MenuEkyte';
import { AvatarTable } from '../../components/AtomicDesign/atoms/AvatarTable';
import { TableSimple } from '../../components/AtomicDesign/atoms/TableSimple';
import { Pagination } from '../../components/AtomicDesign/atoms/Pagination';
import { ModalPropsUser, Tabs } from './interfaces';
import { UnitInfos } from './components/Infos';
import { ModalCreateUser } from '../../components/AtomicDesign/molecules/ModalCreateUser';
import { getMetabaseUrl } from '../../services/requests/Ads/getMetabaseUrl';
import { ModalTransition } from '../../components/AtomicDesign/molecules/ModalTransition';
import { getAccountVerificationStatus } from '../../services/requests/Finance/getAccountVerificationStatus';
import { useCheckAuthentication } from '../../hooks/useCheckAuthentication';

export function UnitVision() {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { user, isNowBlocked, setIsNowBlocked } = useAuth();
  const { permissions } = user;

  const [ekyteUser, setIsEkyteUsers] = useState(
    ({} as InterfaceEkyteUser) || undefined,
  );
  const INIT_PAGE = 0;
  const LIMIT_PAGE = 10;
  const IS_ADMIN = permissions?.unit?.admin || permissions?.headquarter?.admin;

  const [currentPage, setCurrentPage] = useState<number>(INIT_PAGE);

  const [isUnitAccountVerified, setIsUnitAccountVerified] =
    useState<boolean>(true);
  const [tab, setTab] = useState(Tabs.active.toString());
  const [query, setQuery] = useState('');
  const [OpenModalCreateEmail, setOpenModalCreateEmail] = useState(false);
  const debouncedQuery = useDebounce<string>(query);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [openModalTransition, setOpenModalTransition] = useState(false);
  const [openModalUnitEdit, setOpenModalUnitEdit] = useState(false);
  const [unitUpd, getUnitUpd] = useState<Unit>();
  const { unitId: unitIdParams } = useParams();
  const [unit, setUnit] = useState<KubernetesApiUnit['unit']>();
  const { typeUser } = useParams();
  const [modalProps, setModalProps] = useState<ModalPropsUser>();
  const [userUpd, getUserUpd] = useState<User>();
  const [userSelect, setUserSelect] = useState<
    tableDataSimplePropsUnit | undefined
  >();
  const [loading, setLoading] = useState(true);
  const [metabaseUrl, setMetabaseUrl] = useState('');

  const fetchAccountVerificationStatus = useCallback((unitId: string) => {
    getAccountVerificationStatus(unitId).then(({ isVerified }) =>
      setIsUnitAccountVerified(isVerified || false),
    );
  }, []);

  useCheckAuthentication();

  const handlePermission = useCallback(
    async (userId: string, accessLevel: number, unitId: string) => {
      const filtroUsuarios = ekyteUser?.data?.filter(
        (users) => users?.userId === userId,
      );
      if (filtroUsuarios && filtroUsuarios[0]?.userId === userId) {
        const putEkytePermission = await putPermissionEkyte(
          userId,
          accessLevel,
          unitId,
        );
        if (putEkytePermission) {
          addToast({
            type: 'success',
            title: 'Sucesso ao alterar a permissão do seu usuário.',
            description: 'Permissão alterada com sucesso!',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao alterar a permissão do seu usuário.',
          });
        }
      } else {
        const postEkytePermission = await postPermissionEkyte(
          userId,
          accessLevel,
        );
        if (postEkytePermission) {
          addToast({
            type: 'success',
            title: 'Sucesso ao selecionar a permissão do seu usuário.',
            description: 'Permissão selecionada com sucesso!',
          });
        } else {
          const putEkytePermission = await putPermissionEkyte(
            userId,
            accessLevel,
            unitId,
          );
          if (putEkytePermission) {
            addToast({
              type: 'success',
              title: 'Sucesso ao selecionar a permissão do seu usuário.',
              description: 'Permissão selecionada com sucesso!',
            });
          } else {
            addToast({
              type: 'error',
              title: 'Erro ao selecionar a permissão do seu usuário.',
            });
          }
        }
      }
    },
    [addToast, ekyteUser?.data],
  );

  const readUnit = useCallback(async () => {
    try {
      setLoading(true);
      const response = (await getUnit(
        unitIdParams as string,
      )) as KubernetesApiUnit['unit'];

      if (response) {
        response.stakeholders.forEach((stakeholder, index) => {
          response.stakeholders[index].phone = stakeholder.phone?.replace(
            /[^a-zA-Z0-9]/g,
            '',
          );
          response.stakeholders[index].rg = stakeholder.rg?.replace(
            /[^a-zA-Z0-9]/g,
            '',
          );
          response.stakeholders[index].cpf = stakeholder.cpf?.replace(
            /[^a-zA-Z0-9]/g,
            '',
          );
        });

        setUnit(response);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar dados da unidade!',
        description: `Tente novamente mais tarde!`,
      });
    }
    setLoading(false);
  }, [addToast, unitIdParams]);

  useEffect(() => {
    readUnit();
  }, [readUnit]);

  useEffect(() => {
    getMetabaseUrl(29, {
      unit: unitIdParams || '',
    }).then((response) => {
      setMetabaseUrl(response);
    });
  }, [unitIdParams]);

  useEffect(() => {
    if (unitIdParams) {
      fetchAccountVerificationStatus(unitIdParams);
    }
  }, [unitIdParams, fetchAccountVerificationStatus]);

  const { data, refetch, isLoading } = useInfiniteQuery(
    ['users', query, currentPage],
    async () => {
      const usersPromise = await getUsersByUnitId({
        unitId: unitIdParams as string,
        showDeleted: tab === 'Histórico de contas',
        isActive: tab === 'Ativas',
        search: query,
        limit: LIMIT_PAGE,
        page: currentPage + 1,
        withPagination: true,
      });
      return usersPromise;
    },
  );

  useEffect(() => {
    refetch({ refetchPage: (_page, index) => index === 0 });
  }, [debouncedQuery, refetch, tab, userUpd, unitUpd]);

  const handleUnitBlock = useCallback(() => {
    if (unitIdParams) {
      getUnitBlock(unitIdParams).then((responseData) => {
        if (responseData) {
          setIsNowBlocked(true);
          return true;
        }
        setIsNowBlocked(false);
        return false;
      });
    }
  }, [unitIdParams, setIsNowBlocked]);

  useEffect(() => {
    handleUnitBlock();
  }, [handleUnitBlock]);

  const [showUserInvate, setShowUserInvate] = useState<boolean>(false);

  const [allowRegisterEkyte, setAllowRegisterEkyte] = useState(true);
  const [showRegisterEkyteModal, setShowRegisterEkyteModal] = useState(false);
  const [isEkyteActive, setIsEkyteActive] = useState<boolean>(false);

  const [isLockModal, setIsLockModal] = useState<boolean>(false);

  const handleRegisterEkyteCompany = async (values: any) => {
    setLoading(true);
    if (allowRegisterEkyte) {
      await stackApi({
        url: '/ekyte/unit',
        method: 'POST',
        data: {
          id: `${unitIdParams}`,
          ownerEmail: values.ownerEmail,
          contactEmail: values.contactEmail,
        },
      })
        .then((res: { status: number }) => {
          setShowRegisterEkyteModal(false);
          setIsEkyteActive(values.active);
          if (res.status === 200) {
            setAllowRegisterEkyte(false);
            addToast({
              title: 'Sucesso',
              description: 'A unidade foi registrada no Ekyte!',
              type: 'success',
            });
          } else {
            addToast({
              title: 'Atenção',
              description: 'Houve um erro ao registrar a unidade no Ekyte!',
              type: 'error',
            });
          }
        })
        .catch(() => {
          setShowRegisterEkyteModal(false);
          addToast({
            title: 'Atenção',
            description: 'Houve um erro ao registrar a unidade no Ekyte!',
            type: 'error',
          });
        });
    } else {
      await stackApi({
        url: `/ekyte/unit/${unitIdParams}`,
        method: 'PUT',
        data: {
          active: values.active,
        },
      })
        .then((res: { status: number }) => {
          setShowRegisterEkyteModal(false);
          setIsEkyteActive(values.active);
          if (res.status === 200) {
            addToast({
              title: 'Sucesso',
              description: 'A unidade foi atualizada no Ekyte!',
              type: 'success',
            });
          } else {
            addToast({
              title: 'Atenção',
              description: 'Houve um erro ao atualizar a unidade no Ekyte!',
              type: 'error',
            });
          }
        })
        .catch(() => {
          setShowRegisterEkyteModal(false);
          addToast({
            title: 'Atenção',
            description: 'Houve um erro ao atualizar a unidade no Ekyte!',
            type: 'error',
          });
        });
    }
    setLoading(false);
  };

  useMemo(async () => {
    if (unit !== undefined) {
      const ekyteCompany = await getEkyteCompany(unit.id as string);

      if (ekyteCompany === undefined) {
        setAllowRegisterEkyte(true);
        setIsEkyteActive(false);
      } else {
        setAllowRegisterEkyte(false);
        setIsEkyteActive(ekyteCompany.active);
      }

      const ekyteUsers = await getEkyteUser(unit.id as string);
      if (ekyteUsers === undefined) {
        setIsEkyteUsers({} as InterfaceEkyteUser);
      } else {
        setIsEkyteUsers(ekyteUsers);
      }
    }
  }, [unit]);

  const handlerDeleteUser = useCallback(
    async (id: string, name: string, email: string, unitId: string) => {
      setModalProps({
        disableUnitProps: {
          name,
          id,
          email,
          unitId,
        },
        user: userUpd as User,
        getUser: getUserUpd,
      });
      setOpenModalAction(true);
    },
    [userUpd],
  );

  const handleManagementInvestor = useCallback(
    (unitId: string, email: string, name: string, _id?: string) => {
      handlerDeleteUser(email, name || '', _id || '', unitId);
    },
    [handlerDeleteUser],
  );

  const routes = useMemo(() => {
    return [
      {
        title: unit?.name?.replace('&', 'e') || 'Unidade Não Encontrada',
        link: `/unit/view/head/${unit?.id}`,
      },
    ];
  }, [unit]);

  const tableContent = useMemo(() => {
    const tableDataSimple: tableDataSimplePropsUnit[] = [];
    const permissionsEkyte: string[] = [];
    const ACCESS_LEVEL_ADMIN = 100;
    const ACCESS_LEVEL_EDIT = 200;

    data?.pages?.forEach((page) => {
      if (page) {
        const { users } = page;
        users?.forEach(
          ({ _id, name, picture, mandate, seniority, ...currentUser }) => {
            const filtroUsuarios =
              ekyteUser?.data?.filter(
                (ekyteUsers) => ekyteUsers?.userId === _id,
              ) || [];

            const accessLevel = filtroUsuarios[0]?.accessLevel;
            let placeholder = 'Permissões';

            if (accessLevel === ACCESS_LEVEL_EDIT) {
              placeholder = 'Editar';
            }
            if (accessLevel === ACCESS_LEVEL_ADMIN) {
              placeholder = 'Administrar';
            }

            permissionsEkyte.push(placeholder);

            tableDataSimple.push({
              avatar: (
                <AvatarTable
                  routes={routes}
                  _id={_id}
                  name={name}
                  picture={picture}
                />
              ),
              ...currentUser,
              _id,
              name,
              email: currentUser.email,
              picture,
              mandate: mandate?.name || '-',
              seniority: seniority?.name || '-',
              ekyte: (
                <MenuEkyte
                  placeholder={placeholder}
                  actionAdmin={() =>
                    handlePermission(
                      _id,
                      ACCESS_LEVEL_ADMIN,
                      currentUser.unitId,
                    )
                  }
                  actionEdit={() =>
                    handlePermission(_id, ACCESS_LEVEL_EDIT, currentUser.unitId)
                  }
                />
              ),
              action: permissions?.users?.delete ? (
                <MenuActions
                  actionDelete={() =>
                    handleManagementInvestor(
                      currentUser.unitId,
                      _id,
                      name,
                      currentUser.email,
                    )
                  }
                  label="Deletar Usuário"
                />
              ) : null,
            });
          },
        );
      }
    });

    return { tableDataSimple };
  }, [
    data?.pages,
    ekyteUser?.data,
    handleManagementInvestor,
    handlePermission,
    routes,
    permissions,
  ]);

  let headers;
  const ADMIN_HEADQUARTER = permissions?.headquarter?.admin;
  const ADMIN_UNIT =
    permissions?.headquarter?.admin || permissions?.unit?.admin;

  if (tab === 'Histórico de contas') {
    headers = headerTableHistoric;
  } else if (ADMIN_HEADQUARTER) {
    headers = headerTableEkyte;
  } else if (ADMIN_UNIT) {
    headers = headerTable;
  } else {
    headers = headerTableNotAdmin;
  }
  const dataTableSingle: TableSimpleProps['data'] = {
    headers,
    rows: tableContent.tableDataSimple,
  };

  const IsNotHeadquarterAndHavePermission =
    (permissions?.unit?.admin || permissions?.headquarter?.admin) &&
    unitIdParams !== process.env.REACT_APP_HEADQUARTER_ID;

  const handleRowClick = (row: { [key: string]: string | number }) => {
    setShowUserInvate(true);
    setUserSelect(
      tableContent?.tableDataSimple?.find((userRow) => userRow._id === row._id),
    );
  };

  return (
    <>
      {!loading ? (
        <Container>
          {unit ? (
            <RegisterEkyteModal
              unit={unit}
              isShown={showRegisterEkyteModal}
              setIsShown={setShowRegisterEkyteModal}
              handleRegister={handleRegisterEkyteCompany}
              isEkyteActive={isEkyteActive}
            />
          ) : null}
          {typeUser ? null : (
            <Breadcrumb
              breadcrumbs={[{ title: 'Minha Unidade', link: '/unit/read' }]}
            />
          )}

          {unit && (
            <UnitInfos
              unit={unit}
              isHeadquarter={permissions?.headquarter?.admin || false}
              isAdmin={IS_ADMIN || false}
              isNowBlocked={isNowBlocked}
              isUnitAccountVerified={isUnitAccountVerified}
              openModalTransition={() => setOpenModalTransition(true)}
              openModalEkyte={() => setShowRegisterEkyteModal(true)}
              openModalEdit={() => setOpenModalUnitEdit(true)}
            />
          )}
          <Section>
            <SectionContent>
              <iframe
                title="metabase-dashboard"
                src={metabaseUrl}
                height="700"
                width="100%"
              />
            </SectionContent>
          </Section>
          <Section>
            <SectionContent>
              <S.ContentHeader>
                <SectionTitle text="Investidores" />
                {IsNotHeadquarterAndHavePermission && (
                  <LabDsButton
                    variant="outlined"
                    label="Capacidade operacional"
                    leadingIcon="settings"
                    onHandleButton={() => {
                      navigate(
                        `/unit/view/capacidade-operacional/${unitIdParams}`,
                      );
                    }}
                  />
                )}
              </S.ContentHeader>
              <S.Row>
                <LabDsTab
                  value="active"
                  tabs={[
                    {
                      title: 'Ativas',
                      key: 'active',
                      event: () => setTab(Tabs.active),
                    },
                    {
                      title: 'Histórico de contas',
                      key: 'inactive',
                      event: () => setTab(Tabs.inactive),
                    },
                  ]}
                />
              </S.Row>
              <S.Filters>
                <LabDsSearchBar
                  value={query}
                  onChangeInput={({ detail }) => setQuery(detail)}
                  label="Busque por investidor, mandato..."
                />
                <S.Actions>
                  <LabDsButton
                    variant="primary"
                    size="medium"
                    leadingIcon="add"
                    onClick={() => {
                      setOpenModalCreateEmail(true);
                    }}
                    disabled={!permissions?.unit?.admin}
                    label="Novo investidor"
                  />
                </S.Actions>
              </S.Filters>

              <TableSimple
                data={dataTableSingle}
                loading={isLoading}
                onRowClick={handleRowClick}
              />
              {dataTableSingle.rows.length >= 0 && (
                <Pagination
                  forcePage={currentPage}
                  pageCount={data?.pages[0].totalPages || 0}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </SectionContent>
          </Section>
        </Container>
      ) : null}
      {openModalUnitEdit && unit ? (
        <EditUnit
          getUnit={getUnitUpd}
          data={unit}
          setIsOpen={setOpenModalUnitEdit}
          isOpen={openModalUnitEdit}
        />
      ) : null}
      {showUserInvate && tab === 'Ativas' && (
        <ModalPermissionsEdit
          user={userSelect as User}
          setIsOpen={setShowUserInvate}
          isOpen={showUserInvate}
          unitId={unitIdParams as string}
          isNowBlocked={isNowBlocked}
        />
      )}

      {openModalTransition && unit ? (
        <ModalTransition
          isOpen={openModalTransition}
          readUnit={readUnit}
          data={unit}
          setIsOpen={setOpenModalTransition}
        />
      ) : null}

      {modalProps && openModalAction && (
        <ActionUser
          modalProps={modalProps}
          setIsOpen={setOpenModalAction}
          isOpen={openModalAction}
        />
      )}

      {isLockModal && (
        <ModalLockUnit
          isOpen={isLockModal}
          setIsOpen={setIsLockModal}
          unitId={unitIdParams || ''}
          unitName={unit?.name}
        />
      )}
      {OpenModalCreateEmail && (
        <ModalCreateUser
          setIsOpen={setOpenModalCreateEmail}
          isOpen={OpenModalCreateEmail}
          unitId={unit?.id || ''}
        />
      )}
    </>
  );
}
