import { customerApi } from '../../api';

interface CreateStakeholderDTO {
  name: string;
  role: string;
  tel: string;
  email: string;
  mktLabUser: boolean;
}

export async function createStakeholder(
  customerId: string,
  stakeholder: CreateStakeholderDTO,
): Promise<Stakeholder | false> {
  try {
    const response: any = await customerApi.post(
      `/customer/${customerId}/stakeholder`,
      stakeholder,
    );
    const createdStakeholder = response.data;

    return createdStakeholder;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o stakeholder, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
