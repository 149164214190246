import {
  ReactNode,
  useEffect,
  useCallback,
  createContext,
  useContext,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../components/LegacyV4Components';

import { Unit, User } from './types';
import { Loading as LoadingStyle } from '../pages/SignIn/styles';
import * as api from '../services/api';
import { getUnitBlock } from '../services/requests/Unit/getUnit';
import { managerPermissions } from '../types/managerPermissions';
import { getPermissionsByUser } from '../services/requests/ManagerPermissions/ManagerUser';

interface SignInCredentials {
  tokenId: string;
}

interface AuthContextData {
  updateUser: (userData: User) => void;
  user: User;
  signIn({ tokenId }: SignInCredentials): Promise<void>;
  signOut(): void;
  isNowBlocked: boolean;
  setIsNowBlocked: Dispatch<SetStateAction<boolean>>;
  permissionsManager: managerPermissions;
  setPermissionsManager: Dispatch<SetStateAction<managerPermissions>>;
  forceLogout(): void;
}

export interface AuthProps {
  children: ReactNode;
}

interface ISignInRoute {
  token: string;
  unit: Unit;
  user: User;
}

const AuthContext = createContext({} as AuthContextData);

async function handleSetApiTokens(token: string) {
  api.usersApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  api.leadBrokerApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  api.timelineApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  api.customerApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  api.api.defaults.headers.common.Authorization = `Bearer ${token}`;
  api.apiV2.defaults.headers.common.Authorization = `Bearer ${token}`;
  api.dashboardsApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  api.financeApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  api.customerAuthApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  api.customerUsersApi.defaults.headers.common.Authorization = `Bearer ${token}`;
}

async function handleUpdateUserState(
  setUser: React.Dispatch<React.SetStateAction<User>>,
  signOut: () => void,
  setLoadingUser: React.Dispatch<React.SetStateAction<boolean>>,
) {
  try {
    setLoadingUser(true);

    const userRequest = await api.usersApi.get<{ user: User; unit: Unit }>(
      '/user/me',
    );

    if (!userRequest.data) {
      return;
    }

    const { user, unit } = userRequest.data;
    user.unit = unit;
    user.unitId = unit._id;

    setUser(user);
    setLoadingUser(false);
  } catch {
    setLoadingUser(false);
    signOut();
  }
}

async function handleUpdatePermissionsState(
  setPermissionsManager: Dispatch<SetStateAction<managerPermissions>>,
) {
  const permissionsRequest = await getPermissionsByUser();

  setPermissionsManager(permissionsRequest as managerPermissions);
}

function AuthProvider({ children }: AuthProps): JSX.Element {
  const [user, setUser] = useState<User>({} as User);
  const [loadingUser, setLoadingUser] = useState(true);
  const [isNowBlocked, setIsNowBlocked] = useState(false);
  const [permissionsManager, setPermissionsManager] =
    useState<managerPermissions>({} as managerPermissions);
  const navigate = useNavigate();

  function signOut() {
    destroyCookie(undefined, 'v4company.token', {
      domain: process.env.REACT_APP_DOMAIN_NAME,
    });

    setLoadingUser(false);
    setUser({} as User);
  }

  useEffect(() => {
    const { 'v4company.token': token } = parseCookies();

    if (!token) {
      setLoadingUser(false);
      return;
    }

    handleUpdateUserState(setUser, signOut, setLoadingUser);
    handleSetApiTokens(token);
  }, []);

  useEffect(() => {
    if (!user?._id) {
      return;
    }
    handleUpdatePermissionsState(setPermissionsManager);
  }, [user]);

  async function forceLogout() {
    signOut();
    navigate('/');
  }

  async function signIn({ tokenId }: SignInCredentials) {
    const twoDays = 60 * 60 * 24 * 2;
    setLoadingUser(true);

    const response = await api.usersApi.post<ISignInRoute>('/auth', {
      tokenId,
    });

    const { token, user: requestUser, unit } = response.data;
    requestUser.unitId = unit._id;
    requestUser.unit = unit;

    if (requestUser.unitId) {
      getUnitBlock(requestUser.unitId).then((responseData) => {
        if (responseData) {
          setIsNowBlocked(true);
        }
      });
    }

    if (!requestUser) {
      return;
    }

    setUser(requestUser);

    setCookie(undefined, 'v4company.token', token, {
      maxAge: twoDays,
      path: '/',
      domain: process.env.REACT_APP_DOMAIN_NAME,
    });

    handleSetApiTokens(token);
    setLoadingUser(false);
  }

  const updateUser = useCallback(async (userData: User) => {
    const { 'v4company.token': token } = parseCookies();
    if (token) setUser(userData);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        updateUser,
        isNowBlocked,
        setIsNowBlocked,
        permissionsManager,
        setPermissionsManager,
        forceLogout,
      }}
    >
      {loadingUser ? (
        <LoadingStyle style={{ height: '95vh', marginLeft: '6rem' }}>
          <Loading />
        </LoadingStyle>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export { AuthProvider, useAuth, AuthContext };
