import { Location, NavigateFunction } from 'react-router-dom';

const leadBrokerUrl = process.env.REACT_APP_HB_LEADS_FRONT_URL!;
const transitionUrl = process.env.REACT_APP_TRANSITION_URL!;
const meetingBrokerUrl = process.env.REACT_APP_MEETINGBROKER_URL!;
const brokerManagerUrl = process.env.REACT_APP_BROKER_MANAGER_URL!;
const labFinanceiroUrl = process.env.REACT_APP_LABFINANCEIRO!;
const eletromidiaUrl = process.env.REACT_APP_ELETROMIDIA_WEB_URL;

interface MenuOptions {
  unitId: string;
  lbUser: boolean | undefined;
  managerUser: boolean | undefined;
  ekyteActive: boolean | undefined;
  isHeadquarter: boolean;
  transition: boolean;
  token: string;
  navigate: NavigateFunction;
  location: Location;
  eletromidiaToken?: string;
  userId?: string;
}

export function menus({
  unitId,
  lbUser,
  managerUser,
  ekyteActive,
  isHeadquarter,
  transition,
  token,
  navigate,
  location,
  eletromidiaToken,
  userId,
}: MenuOptions) {
  return [
    {
      title: 'Broker',
      slot: 'products-v4',
      icon: 'request_quote',
      active: false,
      conditional: lbUser,
      subMenus: [
        {
          title: 'LeadBroker',
          slot: 'products-v4',
          icon: 'show_chart',
          active: false,
          conditional: lbUser,
          subMenus: [
            {
              title: 'Ofertas',
              icon: 'credit_card',
              active: false,
              event: () => {
                window.location.href = leadBrokerUrl;
              },
            },
            {
              title: 'Meus leads',
              icon: 'group',
              active: false,
              event: () => {
                window.location.href = `${leadBrokerUrl}/meus-leads`;
              },
            },
          ],
        },
        {
          title: 'MeetingBroker',
          slot: 'products-v4',
          icon: 'event',
          active: false,
          conditional: lbUser,
          subMenus: [
            {
              title: 'Ofertas',
              icon: 'credit_card',
              active: false,
              event: () => {
                window.location.href = `${meetingBrokerUrl}`;
              },
            },
            {
              title: 'Minhas Reuniões',
              icon: 'date_range',
              event: () => {
                window.location.href = `${meetingBrokerUrl}/minhas-reunioes`;
              },
              active: false,
            },
          ],
        },
        {
          title: 'Broker Manager',
          slot: 'products-v4',
          icon: 'manage_accounts',
          active: false,
          conditional: managerUser,
          event: () => {
            window.location.href = brokerManagerUrl;
          },
        },
        {
          title: 'Projetos para transição',
          slot: 'products-v4',
          icon: 'swap_horiz',
          active: false,
          conditional: transition,
          subMenus: [
            {
              title: 'Ofertas',
              icon: 'credit_card',
              active: false,
              event: () => {
                window.location.href = `${transitionUrl}`;
              },
            },
            {
              title: 'Meus interesses',
              icon: 'bookmark',
              active: false,
              event: () => {
                window.location.href = `${transitionUrl}/meus-interesses`;
              },
            },
            {
              title: 'Enviar para transição',
              icon: 'subdirectory_arrow_right',
              active: false,
              event: () => {
                window.location.href = `${transitionUrl}/nova-transicao`;
              },
            },
            {
              title: 'Transições recebidas',
              icon: 'task',
              active: false,
              event: () => {
                window.location.href = `${transitionUrl}/minhas-transicoes`;
              },
            },
          ],
        },
        {
          title: 'Carteira',
          slot: 'products-v4',
          icon: 'wallet',
          active: false,
          conditional: lbUser,
          event: () => {
            window.location.href = `${leadBrokerUrl}/carteira`;
          },
        },
      ],
    },
    {
      title: 'Gestor de Projetos',
      slot: 'products-v4',
      icon: 'content_paste',
      active: location.pathname.includes('ekyte'),
      event: () => {
        window.location.href = `${process.env.REACT_APP_EKYTE_LOGIN_URL}?externalToken=${token}`;
      },
      conditional: ekyteActive,
    },
    {
      title: 'MediaHub',
      slot: 'products-v4',
      icon: 'grid_view',
      active: location.pathname.includes('mediahub'),
      event: () => {
        window.location.href = `${process.env.REACT_APP_MEDIAHUB_URL}`;
      },
      conditional: true,
    },
    {
      title: 'Gestão de faturas',
      slot: 'products-v4',
      icon: 'qr_code_scanner',
      active: window.location.pathname.includes('/ManagementBilling'),
      event: () => {
        window.location.href = `${process.env.REACT_APP_V4PAY}/payments/ManagementBilling`;
      },
      conditional: true,
    },
    {
      title: 'Assinaturas',
      slot: 'products-v4',
      icon: 'attach_money',
      active: window.location.pathname.includes('/payments/headquarter'),
      event: () => {
        window.location.href = `${process.env.REACT_APP_V4PAY}/payments/headquarter`;
      },
      conditional: isHeadquarter,
    },
    {
      title: 'Checkout matriz',
      slot: 'products-v4',
      icon: 'account_balance',
      active: window.location.pathname.includes('/gateway/headquarter'),
      event: () => {
        navigate('/gateway/headquarter');
      },
      conditional: isHeadquarter,
    },
    {
      title: 'Eletromidia',
      slot: 'products-v4',
      icon: 'work',
      active: false,
      event: () => {
        eletromidiaToken
          ? (window.location.href = `${eletromidiaUrl}/parceiro/login?token=${eletromidiaToken}`)
          : navigate(`/perfil/${userId}`);
      },
      conditional: true,
    },
    {
      title: 'Central de Dashboards',
      slot: 'products-v4',
      icon: 'bar_chart',
      active: location.pathname === '/' || location.pathname === '/dashboards',
      event: () => {
        navigate('/dashboards');
      },
      conditional: true,
    },
    {
      title: 'Lab Financeiro',
      slot: 'products-v4',
      icon: 'paid',
      active: location.pathname.includes('/finance'),
      event: () => {
        window.location.href = labFinanceiroUrl;
      },
      conditional: true,
    },
    {
      title: 'Headquarter',
      slot: 'rede-v4',
      icon: 'apartment',
      active: location.pathname === '/headquarter',
      event: () => {
        navigate('/headquarter');
      },
      conditional: isHeadquarter,
    },
    {
      title: 'Franquias',
      slot: 'rede-v4',
      icon: 'home_repair_service',
      active:
        location.pathname === '/unidades' ||
        location.pathname.includes('/unit/'),
      event: () => {
        navigate('/unidades');
      },
      conditional: isHeadquarter,
    },
    {
      title: 'Clientes',
      slot: 'rede-v4',
      icon: 'work',
      active:
        location.pathname.includes('/cliente') &&
        !location.pathname.includes('mediahub'),
      event: () => {
        navigate('/clientes');
      },
      conditional: isHeadquarter,
    },
    {
      title: 'Minha Unidade',
      slot: 'rede-v4',
      icon: 'home_repair_service',
      active:
        (location.pathname.startsWith('/cliente') ||
          location.pathname.startsWith('/unit/')) &&
        !location.pathname.includes('mediahub'),
      conditional: !isHeadquarter && unitId,
      subMenus: [
        {
          title: 'Clientes',
          icon: 'work',
          event: () => {
            navigate('/clientes');
          },
          conditional: true,
          active:
            location.pathname.startsWith('/cliente') &&
            !location.pathname.includes('mediahub'),
        },
        {
          title: 'Investidores',
          icon: 'person',
          event: () => {
            navigate(`/unit/read/${unitId}`);
          },
          conditional: true,
          active: location.pathname.startsWith('/unit/read/'),
        },
      ],
    },
  ];
}
