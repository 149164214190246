import { customerUsersApi } from '../../api';

interface CreateStakeholderDTO {
  name: string;
  role: string;
  phone: string;
  email: string;
  customerId: string;
}

export async function createCustomerUser(
  stakeholder: CreateStakeholderDTO,
): Promise<Stakeholder | false> {
  try {
    const response: any = await customerUsersApi.post(`/invite`, stakeholder);
    const createdStakeholder = response.data;

    return createdStakeholder;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o stakeholder, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
