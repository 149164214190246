import { LabDsButton } from 'v4web-components-react';
import { MdInfoOutline } from 'react-icons/md';
import { AiOutlineInfoCircle, AiOutlineUser } from 'react-icons/ai';
import { VscLocation } from 'react-icons/vsc';
import { useNavigate, useParams } from 'react-router-dom';
import { SectionTitle } from '../../../../components/LegacyV4Components';
import * as S from './styles';
import { Unit, KubernetesApiUnit } from '../../../../hooks/types';
import { cepWithSeparator } from '../../../../utils/cepWithSeparator';
import MenuActiveUnit from '../../../../components/AtomicDesign/atoms/MenuActiveEkyte';

interface UnitInfosProps {
  unit: KubernetesApiUnit['unit'];
  isHeadquarter: boolean;
  isNowBlocked: boolean;
  isAdmin: boolean;
  isUnitAccountVerified?: boolean;
  openModalEkyte: () => void;
  openModalTransition: () => void;
  openModalEdit: () => void;
}

export function UnitInfos({
  unit,
  isHeadquarter,
  isNowBlocked,
  openModalEkyte,
  openModalEdit,
  openModalTransition,
  isAdmin,
  isUnitAccountVerified,
}: UnitInfosProps) {
  const navigate = useNavigate();
  const params = useParams();

  const getAddressPart = (
    address: Unit['address'],
    part: keyof Unit['address'],
    index: number,
  ) => {
    const value = address[part];
    return value && `${index !== 0 ? ', ' : ''} ${value}`;
  };

  const getAddress = () => {
    const unitAddress: Unit['address'] = unit?.address || {};

    const addressParts: (keyof Unit['address'])[] = [
      'street',
      'district',
      'city',
      'state',
    ];

    const address = addressParts
      .map((part, index) => getAddressPart(unitAddress, part, index))
      .join('');

    return address;
  };

  return (
    <S.SectionContent>
      <S.DotMenu>
        <S.MenuContent>
          <div>
            <SectionTitle text={unit.name} />
            <MenuActiveUnit
              isAdmin={isAdmin}
              isHeadquarter={isHeadquarter}
              onClickEkyte={openModalEkyte}
              onClickTransition={openModalTransition}
              onClickFinance={() => navigate(`/unit/manage/${unit?.id}`)}
              onClickEdit={openModalEdit}
            />
          </div>
        </S.MenuContent>

        {isNowBlocked && (
          <S.unitLockedMessage>
            <MdInfoOutline size={30} color="var(--primary-dark-3)" /> Esta
            unidade está bloqueada no leadbroker
          </S.unitLockedMessage>
        )}
      </S.DotMenu>

      <S.CardRow>
        <S.CardInfo>
          <S.CardHeader>
            <AiOutlineInfoCircle size={24} />
            <S.CardTitle>Informações empresa</S.CardTitle>
          </S.CardHeader>
          <S.CardBody>
            <S.CardItem>
              <S.CardLabel>Razão Social:</S.CardLabel>
              <span>{unit.socialName}</span>
            </S.CardItem>

            <S.CardItem>
              <S.CardLabel>CNPJ:</S.CardLabel>
              <span>{unit.cnpj}</span>
            </S.CardItem>
            {!isUnitAccountVerified && (
              <S.CardItem>
                <S.CardLabel>Informações Bancárias:</S.CardLabel>
                <LabDsButton
                  variant="primary"
                  size="small"
                  leadingIcon="add"
                  label="Cadastrar"
                  onHandleButton={() =>
                    navigate(
                      `/unidades/${params?.unitId}/register-payment-account`,
                    )
                  }
                />
              </S.CardItem>
            )}
          </S.CardBody>
        </S.CardInfo>

        <S.CardInfo>
          <S.CardHeader>
            <VscLocation size={24} />
            <S.CardTitle>Localização</S.CardTitle>
          </S.CardHeader>
          <S.CardBody>
            <S.CardRow>
              <S.CardItem>
                <S.CardLabel>Endereço:</S.CardLabel>
                <span>{getAddress()}</span>
              </S.CardItem>
            </S.CardRow>
            <S.CardRow>
              <S.CardItem className="sm">
                <S.CardLabel className="sm-label">Número:</S.CardLabel>
                <span className="sm-span">{unit.address?.number ?? '-'}</span>
              </S.CardItem>
              <S.CardItem className="sm">
                <S.CardLabel className="sm-label">Cep:</S.CardLabel>
                <span className="sm-span">
                  {cepWithSeparator(unit.address?.zipCode) ?? '-'}
                </span>
              </S.CardItem>

              <S.CardItem className="sm">
                <S.CardLabel className="sm-label">Complemento:</S.CardLabel>
                <span className="sm-span">
                  {unit.address?.complement ?? '-'}
                </span>
              </S.CardItem>
            </S.CardRow>
          </S.CardBody>
        </S.CardInfo>
        <S.CardInfo>
          <S.CardHeader>
            <AiOutlineUser size={24} />
            <S.CardTitle>Stakeholder Principal</S.CardTitle>
          </S.CardHeader>
          <S.CardBody>
            <S.CardRow>
              <S.CardItem>
                <S.CardLabel>Nome:</S.CardLabel>
                <span>{unit.stakeholders[0].name}</span>
              </S.CardItem>

              <S.CardItem>
                <S.CardLabel>E-mail:</S.CardLabel>
                <span>{unit.stakeholders[0].email}</span>
              </S.CardItem>
            </S.CardRow>
            <S.CardItem>
              <S.CardLabel>Telefone:</S.CardLabel>
              <span>{unit.stakeholders[0].phone}</span>
            </S.CardItem>
          </S.CardBody>
        </S.CardInfo>
      </S.CardRow>
    </S.SectionContent>
  );
}
